<template>
   <v-container style="max-width:1200px;">
      <v-row justify="center">
         <v-col 
            cols="12" xs="12" sm="12" md="9" lg="9" xl="9"
            :class="{'pa-2': $vuetify.breakpoint.smAndDown}"
            >
            <v-card flat style="border: 1px solid #AD145733;">
               <v-card-title 
                  class="py-1 px-4 pl-2"
                  style="background-color:transparent;color:#AD1457;border-bottom: 2px solid #AD1457;">
                  <v-btn icon color="#AD1457"
                     @click="goBack" class="mr-2">
                     <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  Xin nghỉ phép
               </v-card-title>

               <v-card-text 
                  style="background-color:#F4F4F4;"
                  class="py-2 pt-2"
                  :class="{'px-2': $vuetify.breakpoint.smAndDown}">

                  <!-- Error Message -->
                  <div v-if="errorMessage"
                     style="color:red;" class="py-1">
                     {{ errorMessage }}
                  </div>

                  <!-- Select A Time Range -->
                  <div class="mt-2">
                     <div class="pl-1" style="font-size:11px;font-weight:500;line-height:1.5;color:#777777;">
                        Chọn ngày:
                     </div>
                     <div style="display:inline-block;" class="mr-2 mb-3">
                        <date-range-picker @dateChange="onDateChange"/>
                     </div>

                     <div style="display:inline-block;" class="mb-3">
                        <v-select
                           style="min-width:240px;background-color:white;"
                           :style="{'width': $vuetify.breakpoint.smAndDown?'100%':'200px;'}"
                           v-model="activeDateOption"
                           :items="dateOptions"
                           item-value="value"
                           item-text="label"
                           label="Hình thức"
                           outlined
                           dense
                           hide-details
                           ></v-select>
                     </div>
                  </div>
                  <div v-if="activeDateOption=='absent'">
                     <div class="pl-1" 
                        style="font-size:11px;font-weight:500;line-height:1.5;color:#777777;">
                        Giờ vắng mặt:
                     </div>
                     <v-btn v-for="(h, hIndex) in pickedHours" 
                        :key="hIndex"
                        style="min-width:34px;width:34px;height:30px;"
                        color="#AD1457"
                        :outlined="!h.value"
                        dark small
                        @click="h.value=!h.value"
                        class="mr-1 mt-1 text-none">
                        {{h.hour}}h
                     </v-btn>
                  </div>

                  <!-- Description -->
                  <v-textarea
                    class="mt-2"
                    v-model="session.description"
                    placeholder="Lý do"
                    outlined hide-details
                    dense auto-grow
                    :row="3"
                    style="background-color:white;"
                  ></v-textarea>

               </v-card-text>

               <v-card-actions class="px-4">
                  <v-spacer/>
                  <v-btn
                     color="#AD1457" 
                     class="text-none px-4"
                     @click="createASession"
                     style="color:white;">
                     Đăng ký
                  </v-btn>
               </v-card-actions>
            </v-card>
         </v-col> 
      </v-row>
   </v-container>
</template>

<script>
import { mapGetters } from "vuex"
export default {
   computed: {
      ...mapGetters({
         userId: "userId",
         token: "token",
         role: "role",
         fullname: "getFullName",
      })
   },
   data () {
      return {
         session: {
            description: ''
         },
         activeDateOption: 'full-date',
         dateOptions: [
            {label: 'Nghỉ phép - cả ngày', value:'full-date'},
            {label: 'Nghỉ phép - buổi sáng', value:'off-morning'},
            {label: 'Nghỉ phép - buổi chiều', value:'off-afternoon'},
            {label: 'Nghỉ không lương', value:'full-date-nopayment'},
            {label: 'Nghỉ chế độ: cưới xin-hiếu hỉ...', value:'full-date-legal'},
            {label: 'Vắng mặt', value:'absent'},
            {label: 'Làm việc online', value:'work-from-home'}
         ],
         errorMessage: '',
         range: {
            start: this.$moment()
               .startOf("date")
               .toDate(),
            end: this.$moment()
               .endOf("date")
               .toDate(),
         },
         pickedHours: [
            {hour: 9, value: false},
            {hour: 10, value: false},
            {hour: 11, value: false},
            {hour: 13, value: false},
            {hour: 14, value: false},
            {hour: 15, value: false},
            {hour: 16, value: false},
            {hour: 17, value: false},
         ],
      }
   },
   methods: {
      goBack () {
         this.$router.go(-1)
      },
      onDateChange (dateRange) {
         this.range = dateRange.range
         this.rangeMode = dateRange.rangeMode
      },
      createASession () {
         if(this.session.description.trim().length <=0 ){
            alert('Bắt buộc phải điền lý do')
            return
         }
         let self = this
         self.session.code = "OFF-ORDER-" + new Date().getTime()
         self.session.start_time = this.range.start
         self.session.end_time = this.range.end
         self.session.user_id = this.userId
         self.session.type = 'off-order'
         self.session.props = { offType: this.activeDateOption }
         if (this.activeDateOption=='absent') {
            self.session.props.pickedHours = this.pickedHours
         }
         self.session.department_id = ""
         this.axios
         .post(this.$root.apiAssetMana + "/sessions", 
            self.session, 
            { headers: { "x-auth": self.token } })
         .then((res) => {
            if (res.data.status === "OK") {
               alert("Đăng ký thành công!")
               self.$router.push({path:'/off-order'})
            } else {
               alert("Lỗi: " + res.data.message)
            }
         })
         .catch((err) => {
            alert("Lỗi: " + err.message)
         })
      }
   }
}
</script>

<style>
   .rent-inline-block {
      display: inline-block;
   }
</style>